import React, { useEffect, useState } from "react";
import useScript from "./hooks/useScript";
import "./App.css";

function App() {
  const gripStatus = useScript(
    `https://resource.grip.show/js/grip.mini.2.min.js?ts=${Date.now()}`
  );

  useEffect(() => {
    if (gripStatus === "ready") {
      // eslint-disable-next-line no-undef
      const mini = GripMini({
        parentId: "embed",
        reservationId: process.env.REACT_APP_RESEVATION_ID,
        serviceId: process.env.REACT_APP_SERVICE_ID,
        lang: "eng",
      });

      mini.on("needLogin", function (e) {
        setOpen(true);
        return true;
      });

      mini.on("share", function (e) {
        alert("공유 버튼 누름");
        return true;
      });

      mini.on("tag", function (evt) {
        console.log("tag", evt);
        return true;
      });
    }
  }, [gripStatus]);

  const [open, setOpen] = useState(false);

  return (
    <div className="container">
      <div className="grip-wrapper">
        <div className="grip" id="embed"></div>
      </div>
      <div className={open ? "popup open" : "popup"}>
        <div className="popup-wrapper">
          <p>로그인이 필요한 기능입니다.</p>
          <button className="login-btn" onClick={() => setOpen(false)}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
